// extracted by mini-css-extract-plugin
export var Contact = "post-module---contact--c7ca4";
export var Primary = "post-module---primary--57516";
export var author = "post-module--author--03b8b post-module--meta--c773f";
export var authorInfo = "post-module--authorInfo--9cce3";
export var avatar = "post-module--avatar--2e3eb";
export var button = "post-module--button--2f454";
export var card = "post-module--card--e7902";
export var content = "post-module--content--cd7b8";
export var dark = "post-module--dark--57e2d";
export var gatsbyHighlight = "post-module--gatsby-highlight--6ef92";
export var ggMoon = "post-module--gg-moon--e5b18";
export var ggSun = "post-module--gg-sun--29a28";
export var grids = "post-module--grids--cd7b7";
export var header = "post-module--header--5f216";
export var image = "post-module--image--bc535";
export var leading = "post-module--leading--7646f";
export var light = "post-module--light--7cf08";
export var link = "post-module--link--825a1";
export var meta = "post-module--meta--c773f";
export var modeContainer = "post-module--mode-container--d162d";
export var post = "post-module--post--14ae4";
export var postThumbnail = "post-module--post-thumbnail--34514";
export var themeChanger = "post-module--theme-changer--70d10";
export var themeChangerInput = "post-module--theme-changer-input--c9235";
export var thumbnail = "post-module--thumbnail--a5bc8";
export var title = "post-module--title--5b75d";
export var twoGrids = "post-module--two-grids--05588";