// extracted by mini-css-extract-plugin
export var Contact = "taglist-module---contact--dfe82";
export var Primary = "taglist-module---primary--ee577";
export var button = "taglist-module--button--33c55";
export var dark = "taglist-module--dark--72338";
export var gatsbyHighlight = "taglist-module--gatsby-highlight--8ef15";
export var ggMoon = "taglist-module--gg-moon--2c6b1";
export var ggSun = "taglist-module--gg-sun--156f0";
export var grids = "taglist-module--grids--9fd53";
export var item = "taglist-module--item--d4e5b";
export var leading = "taglist-module--leading--04494";
export var light = "taglist-module--light--c6c18";
export var modeContainer = "taglist-module--mode-container--ea413";
export var postThumbnail = "taglist-module--post-thumbnail--ce3df";
export var tag = "taglist-module--tag--b955f";
export var taglist = "taglist-module--taglist--6233b";
export var themeChanger = "taglist-module--theme-changer--8f9ac";
export var themeChangerInput = "taglist-module--theme-changer-input--03704";
export var twoGrids = "taglist-module--two-grids--37892";