import React from 'react';
import Helmet from 'react-helmet';
import Navigation from '../components/navigation';
import useSiteMetadata from './siteMetadata';
import Logo from '../components/logo';
import ThemeChanger from '../components/themeChanger';
import PropTypes from 'prop-types';
import * as styles from '../styles/layout.module.scss';

const Layout = ({ children }) => {
  const { title } = useSiteMetadata();
  return (
    <>
      <Helmet defer={false}>
        {/* See: https://github.com/closeio/close-ui/blob/master/webpack/templates/_branding.tpl.html */}
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link
          rel="manifest"
          href="/manifest.webmanifest"
          crossOrigin="use-credentials"
        />
      </Helmet>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <a
            href="https://close.com"
            className={styles.logo}
            title="Visit Close.com"
          >
            <Logo />
          </a>
          <Navigation />
          <ThemeChanger />
        </header>
        {children}
        <footer className={styles.footer}>
          <p>
            &copy; {new Date().getFullYear()}{' '}
            <a href="https://close.com">Close</a> &bull; <a href="/">{title}</a>{' '}
            &bull; <a href="https://developer.close.com/">API</a> &bull;{' '}
            <a href="https://jobs.close.com/">Jobs</a>
          </p>
        </footer>
      </div>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node,
};
