// extracted by mini-css-extract-plugin
export var Contact = "pagination-module---contact--5683c";
export var Primary = "pagination-module---primary--be58d";
export var button = "pagination-module--button--9aaa3";
export var dark = "pagination-module--dark--f597f";
export var gatsbyHighlight = "pagination-module--gatsby-highlight--590d6";
export var ggMoon = "pagination-module--gg-moon--5a3b5";
export var ggSun = "pagination-module--gg-sun--65540";
export var grids = "pagination-module--grids--5a7a1";
export var leading = "pagination-module--leading--abc91";
export var light = "pagination-module--light--b3609";
export var modeContainer = "pagination-module--mode-container--4690d";
export var paginationButton = "pagination-module--paginationButton--7dfb3";
export var paginationButtonActive = "pagination-module--paginationButtonActive--b4b17 pagination-module--paginationButton--7dfb3";
export var paginationButtonEnabled = "pagination-module--paginationButtonEnabled--647fd pagination-module--paginationButton--7dfb3";
export var paginaton = "pagination-module--paginaton--7b00a";
export var postThumbnail = "pagination-module--post-thumbnail--f8048";
export var themeChanger = "pagination-module--theme-changer--e8116";
export var themeChangerInput = "pagination-module--theme-changer-input--28f56";
export var twoGrids = "pagination-module--two-grids--05700";