// extracted by mini-css-extract-plugin
export var Contact = "logo-module---contact--b3974";
export var Primary = "logo-module---primary--e7334";
export var button = "logo-module--button--c6fa9";
export var dark = "logo-module--dark--3d9c5";
export var gatsbyHighlight = "logo-module--gatsby-highlight--60e3a";
export var ggMoon = "logo-module--gg-moon--18424";
export var ggSun = "logo-module--gg-sun--ac4aa";
export var grids = "logo-module--grids--52b0b";
export var leading = "logo-module--leading--05526";
export var light = "logo-module--light--738df";
export var logo = "logo-module--logo--1edaa";
export var modeContainer = "logo-module--mode-container--d741f";
export var postThumbnail = "logo-module--post-thumbnail--c934c";
export var themeChanger = "logo-module--theme-changer--c0fc8";
export var themeChangerInput = "logo-module--theme-changer-input--86a2b";
export var twoGrids = "logo-module--two-grids--ea000";