// extracted by mini-css-extract-plugin
export var Contact = "navigation-module---contact--76fd6";
export var Primary = "navigation-module---primary--e6920";
export var button = "navigation-module--button--c8ef8";
export var dark = "navigation-module--dark--beefe";
export var gatsbyHighlight = "navigation-module--gatsby-highlight--e1d29";
export var ggMoon = "navigation-module--gg-moon--3a0d9";
export var ggSun = "navigation-module--gg-sun--20dbb";
export var grids = "navigation-module--grids--8c680";
export var leading = "navigation-module--leading--03763";
export var light = "navigation-module--light--9eead";
export var link = "navigation-module--link--c51b5";
export var modeContainer = "navigation-module--mode-container--d3e34";
export var navigation = "navigation-module--navigation--b4b7f";
export var navlist = "navigation-module--navlist--e062c";
export var postThumbnail = "navigation-module--post-thumbnail--a38ce";
export var themeChanger = "navigation-module--theme-changer--5302d";
export var themeChangerInput = "navigation-module--theme-changer-input--2effb";
export var twoGrids = "navigation-module--two-grids--85d79";