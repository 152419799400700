import './src/styles/global.scss';
import './src/styles/prismjs/theme.css';

console.log(
  "\n%cHello! Welcome to The Making of Close!%c\n\n\n%cWe're hiring:%c https://www.close.com/careers\n",
  'font-size: 24px; background-color: #1463ff; color: #fff; padding: 4px;',
  '',
  'font-size: 20px; background-color: #d61549; color: #fff; padding: 4px;',
  'font-size: 18px;',
);
